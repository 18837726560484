<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>科室设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" style="text-align: center">
            <el-form-item label="院区">
                <select-hospital v-model="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item label="类型">
                <select-type v-model="search.type_id" :hospital_id="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="search.keywords" size="small" placeholder="关键词" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain size="small" @click="getData">检索</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom:10px">
            <el-button type="primary" size="small" @click="handleAdd">添加科室</el-button>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)"
                                   size="small"
                                   type="warning"
                                   icon="el-icon-edit">
                        </el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <el-dialog title="设置" :visible.sync="dialogVisible" width="40%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="院区" prop="hospital_id">
                    <select-hospital v-model="form.hospital_id"/>
                </el-form-item>
                <el-form-item label="类型" prop="type_id">
                    <select-type v-model="form.type_id" :hospital_id="form.hospital_id"/>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <el-input v-model="form.location" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="顺序">
                    <el-input-number v-model="form.sort" style="width:80%"></el-input-number>
                </el-form-item>
                <el-form-item label="共享号源">
                    <el-switch v-model="form.shared" active-color="#13ce66" inactive-color="#cccccc"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        data() {
            function sharedFormatter(t) {
                return t.shared ? '是' : '否'
            }
            return {
                loading: false,
                hospitalList: [],
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: ''},
                        {title: '类型', field: 'type.name', width: ''},
                        {title: '名称', field: 'name', width: ''},
                        {title: '位置', field: 'location', width: ''},
                        {title: '排序', field: 'sort', width: ''},
                        {title: '共享号源', field: 'shared', width: '', formatter:sharedFormatter},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                search: {},
                dialogVisible: false,
                form: {
                    hospital_id: null,
                    type: '',
                    type_id: null,
                    name: '',
                    location: '',
                    sort: 0,
                    shared: false,
                },
                rules: {
                    hospital_id: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    type_id: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {hospital_id: null, name: '', location: '', shared: false}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.dialogVisible = true
                this.form = row
            },
            async handleDel(index, row) {
                this.$refs[`popover-${index}`].doClose()
                const resp = await this.$http.delete(`/mingde/department/${row.id}`)
                if (resp.data.code == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.msg)
                this.getData()
            },
            async addSubmit() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/department/${this.form.id}` : `/mingde/department/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData() {
                const hl = await this.$http.get('/mingde/hospital/')
                this.hospitalList = hl.data.data

                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                const resp = await this.$http.get('/mingde/department/', {params: params})
                this.table.data = resp.data.data.data || []
                this.table.total = resp.data.data.total
            },
            handleSearch(e) {
                this.table.page = 1
                this.search = e
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>
